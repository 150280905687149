import React from "react";

import { ContextMenu } from "@mchm/common";

import { weekdays } from "./weekdays";

import { DishOperationSubjectInterface } from "./SharedTypes";
import { Weekday } from "../../SharedTypes";
import { CoordsInterface } from "@mchm/common";

interface WeekdayMenuItemInterface {
 name: Weekday;
 action: () => void;
}

type WeekdayMenuItemsType = WeekdayMenuItemInterface[];

interface MoveDishMenuProps {
 visible: boolean;
 contextMenuTriggerCoords: CoordsInterface;
 moveDishOperationSubject: DishOperationSubjectInterface;
 moveDish: (
  moveDishOperationSubject: DishOperationSubjectInterface,
  targetDay: Weekday
 ) => void;
 closeMenu: () => void;
}

const MoveDishMenu = ({
 visible,
 contextMenuTriggerCoords,
 moveDishOperationSubject,
 moveDish,
 closeMenu,
}: MoveDishMenuProps) => {
 const provideMenuItems = () => {
  const result = weekdays.reduce<WeekdayMenuItemsType>((arr, day) => {
   if (day !== moveDishOperationSubject.sourceDay) {
    arr.push({
     name: day,
     action: () => {
      closeMenu();
      moveDish(moveDishOperationSubject, day);
     },
    });
   }
   return arr;
  }, []);

  return result;
 };

 return (
  <ContextMenu
   visible={visible}
   menuItems={provideMenuItems()}
   contextMenuTriggerCoords={contextMenuTriggerCoords}
  />
 );
};

export default MoveDishMenu;
