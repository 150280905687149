import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { useRegularVision } from "@mchm/common";
import { StylingProvider } from "@mchm/common";

import Home from "./Pages/Home";
import Recipes from "./Pages/Recipes/Recipes";
import Recipe from "./Pages/Recipe/Recipe";
import RecipeFormPage from "./Pages/RecipeForm/RecipeFormPage";
import Schedule from "./Pages/Schedule/Schedule";
import DishPicker from "./Pages/DishPicker";
import AddScheduleToList from "./Pages/AddScheduleToList";
import Settings from "./Pages/Settings";

const App = () => {
 const { regularVisionOn, regularVisionSwitch } = useRegularVision();

 return (
  <StylingProvider regularVisionOn={regularVisionOn}>
   <Router>
    <Routes>
     <Route
      path="/:visitSource?"
      element={<Home />}
     />
     <Route
      path="/recipes"
      element={<Recipes />}
     />
     <Route
      path="/recipe/:name"
      element={<Recipe />}
     />
     <Route
      path="/recipe/edit/:recipeName"
      element={<RecipeFormPage />}
     />
     <Route
      path="/addRecipe/"
      element={<RecipeFormPage />}
     />
     <Route
      path="/schedule/"
      element={<Schedule />}
     />
     <Route
      path="/schedule/dishPicker/:targetDay"
      element={<DishPicker />}
     />
     <Route
      path="/schedule/addToList"
      element={<AddScheduleToList />}
     />
     <Route
      path="/settings/"
      element={
       <Settings
        regularVisionSwitch={regularVisionSwitch}
        regularVisionOn={regularVisionOn}
       />
      }
     />
    </Routes>
   </Router>
  </StylingProvider>
 );
};

export default App;
