import React from "react";
import { useParams } from "react-router-dom";

import { Button, PageContent } from "@mchm/common";

import { visitorAlert } from "@mchm/common";

const Home = () => {
 const { visitSource } = useParams();

 visitorAlert("chefin", `home-${visitSource ?? ""}`);

 return (
  <>
   <PageContent withNav={false}>
    <h1>Chefin</h1>
    <ul className="flex-column">
     <li>
      <Button href="/recipes/">Recipes</Button>
     </li>
     <li>
      <Button href="/schedule/">Schedule</Button>
     </li>
     <li>
      <Button href="/settings/">Settings</Button>
     </li>
     {/* <Button href="/editRecipe/">Edit recipe</Button> */}
    </ul>
   </PageContent>
  </>
 );
};

export default Home;
