import React, { useState } from "react";

import "./RecalculateModal.css";

import { Modal, NumericInput } from "@mchm/common";

import { RecipeInterface } from "../../SharedTypes";

interface RecalculateModalProps {
 visible: boolean;
 recipe: RecipeInterface;
 onCancel: () => void;
 recalculateIngredients: (
  recalculationInput: {
   ingredient: string;
   quantity: number;
  },
  callback: () => void
 ) => void;
}

//TODO: Clicking on backdrop doesnt clear selectedIngredient
//TODO: On iOS when input is focused entire body becomes scrollable
//TODO: When base ingredient has no quantity multiplier gets calculated by dividing by 0
//TODO: Base ingredient selection looks bad
const RecalculateModal = ({
 visible,
 recipe,
 onCancel,
 recalculateIngredients,
}: RecalculateModalProps) => {
 const [selectedIngredient, setSelectedIngredient] = useState<string | null>(
  null
 );

 const [newQuantity, setNewQuantity] = useState("");

 let modalConfig: null | {
  message: string[];
  onConfirm?: () => void;
 } = null;

 if (selectedIngredient === null) {
  modalConfig = {
   message: ["Select base ingredient"],
  };
 } else {
  modalConfig = {
   message: ["Provide quantity"],
   onConfirm: () => {
    recalculateIngredients(
     {
      ingredient: selectedIngredient,
      quantity: Number(newQuantity),
     },
     () => setSelectedIngredient(null)
    );
   },
  };
 }

 const renderIngredientList = () => {
  return (
   <ul className="recalculate-modal-ingredients-list">
    {recipe.ingredients.map((ingredient) => {
     return (
      <li
       key={ingredient.name}
       onClick={() => setSelectedIngredient(ingredient.name)}
      >
       {ingredient.name}
      </li>
     );
    })}
   </ul>
  );
 };

 const renderQuantityInput = () => {
  return (
   <NumericInput
    id="quantity"
    label="Quantity"
    onValueChange={(newValue) => setNewQuantity(newValue)}
   />
  );
 };

 return (
  <Modal
   visible={visible}
   message={modalConfig.message}
   onCancel={() => {
    setSelectedIngredient(null);
    onCancel();
   }}
   onConfirm={modalConfig.onConfirm}
  >
   {selectedIngredient === null
    ? renderIngredientList()
    : renderQuantityInput()}
  </Modal>
 );
};

export default RecalculateModal;
