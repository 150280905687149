import React from "react";

import { ContextMenu } from "@mchm/common";
import { CoordsInterface } from "@mchm/common";

interface DishMenuProps {
 visible: boolean;
 contextMenuTriggerCoords: CoordsInterface;
 openMoveDishMenu: () => void;
 removeFromSchedule: () => void;
}

const DishMenu = ({
 visible,
 contextMenuTriggerCoords,
 openMoveDishMenu,
 removeFromSchedule,
}: DishMenuProps) => {
 const menuItems = [
  {
   name: "Move",
   action: () => {
    openMoveDishMenu();
   },
  },
  {
   name: "Remove",
   action: () => removeFromSchedule(),
  },
  // {
  //  name: "Servings",
  //  action: () => {},
  // },
 ];

 return (
  <ContextMenu
   visible={visible}
   contextMenuTriggerCoords={contextMenuTriggerCoords}
   menuItems={menuItems}
  />
 );
};

export default DishMenu;
