import { useState } from "react";

import { CoordsInterface } from "@mchm/common";

type OverlayNameType = "pageMenu" | "recalculateModal" | null;

interface OverlayStateInterface {
 openedOverlay: OverlayNameType;
 triggerCoords: CoordsInterface;
}

const useOverlayHandle = () => {
 const [overlayState, setOverlayState] = useState<OverlayStateInterface>({
  openedOverlay: null,
  triggerCoords: { x: 0, y: 0 },
 });

 const openPageMenu = (triggerCoords: CoordsInterface) => {
  setOverlayState({
   openedOverlay: "pageMenu",
   triggerCoords,
  });
 };

 const openRecalculateModal = () => {
  setOverlayState((current) => {
   return {
    ...current,
    openedOverlay: "recalculateModal",
   };
  });
 };

 const closeOverlay = () => {
  setOverlayState({
   openedOverlay: null,
   triggerCoords: { x: 0, y: 0 },
  });
 };

 return {
  overlayState,
  openPageMenu,
  openRecalculateModal,
  closeOverlay,
 };
};

export default useOverlayHandle;
