import { useState } from "react";
import Axios from "axios";

import { weekdays } from "./weekdays";
import { DishOperationSubjectInterface } from "./SharedTypes";
import { ScheduleType, Weekday } from "../../SharedTypes";

const useScheduleHandle = () => {
 const [schedule, setSchedule] = useState<ScheduleType | null>(null);

 const clearSchedule = () => {
  if (schedule !== null) {
   const clearedSchedule = weekdays.reduce<ScheduleType>((obj, day) => {
    return {
     ...obj,
     [day]: [],
    };
   }, schedule);
   setSchedule(clearedSchedule);
   uploadChanges(clearedSchedule);
  }
 };

 const moveDish = (
  moveDishOperationSubject: DishOperationSubjectInterface,
  targetDay: Weekday
 ) => {
  if (schedule !== null) {
   let mutableSourceArray = [...schedule[moveDishOperationSubject.sourceDay]];
   let mutableTargetArray = [...schedule[targetDay]];
   mutableSourceArray = mutableSourceArray.filter(
    (dish) => dish !== moveDishOperationSubject.dish
   );
   mutableTargetArray.push(moveDishOperationSubject.dish);
   const updatedSchedule = {
    ...schedule,
    [moveDishOperationSubject.sourceDay]: mutableSourceArray,
    [targetDay]: mutableTargetArray,
   };
   setSchedule(updatedSchedule);
   uploadChanges(updatedSchedule);
  }
 };

 const removeDish = (operationSubject: DishOperationSubjectInterface) => {
  if (schedule !== null) {
   let mutableArray = [...schedule[operationSubject.sourceDay]];

   mutableArray = mutableArray.filter((dish) => dish !== operationSubject.dish);

   const updatedSchedule = {
    ...schedule,
    [operationSubject.sourceDay]: mutableArray,
   };

   setSchedule(updatedSchedule);
   uploadChanges(updatedSchedule);
  }
 };

 const uploadChanges = (changedSchedule: ScheduleType) => {
  Axios.post(`${process.env.REACT_APP_BACKEND_URL}/schedule`, changedSchedule);

  //TODO: Handle response
 };

 return {
  schedule,
  setSchedule,
  clearSchedule,
  moveDish,
  removeDish,
 };
};

export default useScheduleHandle;
