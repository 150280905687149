import React from "react";
import { useNavigate } from "react-router-dom";

import { ContextMenu } from "@mchm/common";

import { CoordsInterface } from "@mchm/common";

interface PageMenuProps {
 visible: boolean;
 contextMenuTriggerCoords: CoordsInterface;
 closeMenu: () => void;
 clearSchedule: () => void;
}

const PageMenu = ({
 visible,
 contextMenuTriggerCoords,
 closeMenu,
 clearSchedule,
}: PageMenuProps) => {
 const navigateTo = useNavigate();

 const menuItems = [
  {
   name: "Add to shopping list",
   action: () => {
    navigateTo("/schedule/addToList");
   },
  },
  {
   name: "Clear",
   action: () => {
    closeMenu();
    clearSchedule();
   },
  },
 ];

 return (
  <ContextMenu
   visible={visible}
   menuItems={menuItems}
   contextMenuTriggerCoords={contextMenuTriggerCoords}
  />
 );
};

export default PageMenu;
