import { Weekday } from "../../SharedTypes";

export const weekdays: Weekday[] = [
 "Mon",
 "Tue",
 "Wed",
 "Thu",
 "Fri",
 "Sat",
 "Sun",
];
