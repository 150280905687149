import React, { useEffect, useState } from "react";

import { visitorAlert } from "@mchm/common";
import {
 Button,
 Fetcher,
 Backdrop,
 PageContent,
 SearchBox,
 Toolbar,
} from "@mchm/common";

import PageMenu from "./PageMenu";

import { CoordsInterface } from "@mchm/common";

import useRecipesHandle from "./useRecipesHandle";

const Recipes = () => {
 const [isPageMenuOpened, setIsPageMenuOpened] = useState(false);
 const [pageMenuTriggerCoords, setPageMenuTriggerCoords] =
  useState<CoordsInterface>({ x: 0, y: 0 });
 const [isInSearchMode, setIsInSearchMode] = useState(false);

 const { recipes, setSearchPhrase, onRecipesFetched, getRecipesListToDisplay } =
  useRecipesHandle();

 useEffect(() => {
  visitorAlert("chefin", "recipes");
 }, []);

 const cancelSearch = () => {
  setIsInSearchMode(false);
  setSearchPhrase("");
 };

 return (
  <>
   <Backdrop
    visible={isPageMenuOpened}
    onClick={() => {
     setIsPageMenuOpened(false);
    }}
   />
   <PageMenu
    visible={isPageMenuOpened}
    contextMenuTriggerCoords={pageMenuTriggerCoords}
    closeMenu={() => {
     setIsPageMenuOpened(false);
    }}
    startSearch={() => {
     setIsInSearchMode(true);
    }}
   />
   {isInSearchMode === false && (
    <Toolbar
     backLabel="Chefin"
     backPath="/"
     onEllipsisClick={(event) => {
      setPageMenuTriggerCoords({ x: event.clientX, y: event.clientY });
      setIsPageMenuOpened(true);
     }}
    />
   )}
   {isInSearchMode && <Toolbar closeAction={() => cancelSearch()} />}
   <PageContent withNav>
    {isInSearchMode === true && (
     <SearchBox
      onChange={setSearchPhrase}
      placeholder="Search"
     />
    )}
    {isInSearchMode === false && (
     <>
      <h1>Recipes</h1>
      <Fetcher
       isDataFetchNeeded={recipes === null}
       url={`${process.env.REACT_APP_BACKEND_URL}/recipes`}
       onFetched={(data) => {
        onRecipesFetched(data);
       }}
       dataName="recipes"
      />
     </>
    )}

    <ul className="flex-column">
     {getRecipesListToDisplay().map((recipe) => {
      return (
       <li key={recipe}>
        <Button href={`/recipe/${recipe}`}>{recipe}</Button>
       </li>
      );
     })}
    </ul>
   </PageContent>
  </>
 );
};

export default Recipes;
