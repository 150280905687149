import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./Schedule.css";

import {
 Card,
 EllipsisButton,
 Fetcher,
 Backdrop,
 PageContent,
 Toolbar,
} from "@mchm/common";

import PageMenu from "./PageMenu";
import DishMenu from "./DishMenu";
import MoveDishMenu from "./MoveDishMenu";

import useScheduleHandle from "./useScheduleHandle";

import { weekdays } from "./weekdays";

import { DishOperationSubjectInterface } from "./SharedTypes";
import { CoordsInterface } from "@mchm/common";

type ContextMenuNameType = "pageMenu" | "dishMenu" | "moveDishMenu";

const Schedule = () => {
 const navigateTo = useNavigate();

 const [showBackdrop, setShowBackdrop] = useState(false);
 const [contextMenuToShow, setContextMenuToShow] =
  useState<ContextMenuNameType | null>(null);
 const [contextMenuTriggerCoords, setContextMenuTriggerCoords] =
  useState<CoordsInterface>({ x: 0, y: 0 });

 const { schedule, setSchedule, clearSchedule, moveDish, removeDish } =
  useScheduleHandle();

 const [dishOperationSubject, setDishOperationSubject] =
  useState<DishOperationSubjectInterface | null>(null);

 const openMenu = (
  contextMenuName: ContextMenuNameType,
  clickEvent: React.MouseEvent<Element, MouseEvent>
 ) => {
  setContextMenuTriggerCoords({ x: clickEvent.clientX, y: clickEvent.clientY });
  setShowBackdrop(true);
  setContextMenuToShow(contextMenuName);
 };

 const closeMenu = () => {
  setShowBackdrop(false);
  setContextMenuToShow(null);
 };

 return (
  <>
   <Backdrop
    visible={showBackdrop}
    onClick={closeMenu}
   />
   <PageMenu
    visible={contextMenuToShow === "pageMenu"}
    contextMenuTriggerCoords={contextMenuTriggerCoords}
    closeMenu={closeMenu}
    clearSchedule={clearSchedule}
   />
   {dishOperationSubject !== null && (
    <>
     <DishMenu
      visible={contextMenuToShow === "dishMenu"}
      contextMenuTriggerCoords={contextMenuTriggerCoords}
      openMoveDishMenu={() => setContextMenuToShow("moveDishMenu")}
      removeFromSchedule={() => {
       removeDish(dishOperationSubject);
       closeMenu();
      }}
     />
     <MoveDishMenu
      visible={contextMenuToShow === "moveDishMenu"}
      contextMenuTriggerCoords={contextMenuTriggerCoords}
      moveDishOperationSubject={dishOperationSubject}
      closeMenu={closeMenu}
      moveDish={moveDish}
     />
    </>
   )}
   <Toolbar
    backPath="/"
    backLabel="Chefin"
    onEllipsisClick={(event) => {
     openMenu("pageMenu", event);
    }}
   />
   <PageContent
    withNav
    className="schedule-page"
   >
    <Fetcher
     isDataFetchNeeded={schedule === null}
     url={`${process.env.REACT_APP_BACKEND_URL}/schedule`}
     onFetched={(data) => setSchedule(data)}
    />

    {schedule && (
     <ul className="flex-column">
      {weekdays.map((day) => {
       if (schedule[day].length !== 0) {
        return (
         <li key={day}>
          <div className="day-heading">
           <h2>{day}</h2>
           <button onClick={() => navigateTo(`/schedule/dishPicker/${day}`)}>
            Edit
           </button>
          </div>
          <ul className="flex-column">
           {schedule[day].map((dish) => {
            return (
             <li key={dish}>
              <Card variant="actionable">
               <span>{dish}</span>
               <EllipsisButton
                onClick={(event) => {
                 setDishOperationSubject({ sourceDay: day, dish: dish });
                 openMenu("dishMenu", event);
                }}
               />
              </Card>
             </li>
            );
           })}
          </ul>
         </li>
        );
       }
       return null;
      })}
      {weekdays.map((day) => {
       if (schedule[day].length === 0) {
        return (
         <li key={day}>
          <div className="day-heading">
           <h2>{day}</h2>
           <button onClick={() => navigateTo(`/schedule/dishPicker/${day}`)}>
            Edit
           </button>
          </div>
         </li>
        );
       }
       return null;
      })}
     </ul>
    )}
   </PageContent>
  </>
 );
};

export default Schedule;
