import { useCallback, useState } from "react";

import { RecipeInterface } from "../../SharedTypes";

const useRecipesHandle = () => {
 const [recipes, setRecipes] = useState<string[] | null>(null);
 const [searchPhrase, setSearchPhrase] = useState<string>("");

 const onRecipesFetched = useCallback(
  (data: RecipeInterface[]) => {
   setRecipes(data.map((recipe) => recipe.name));
  },
  [setRecipes]
 );

 const getRecipesListToDisplay = () => {
  if (recipes !== null) {
   return recipes.filter((recipeName) =>
    recipeName.toLowerCase().includes(searchPhrase.toLowerCase())
   );
  } else {
   return [];
  }
 };

 return {
  recipes,
  setSearchPhrase,
  onRecipesFetched,
  getRecipesListToDisplay,
 };
};

export default useRecipesHandle;
