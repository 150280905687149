import React from "react";

import "./Settings.css";

import { visitorAlert } from "@mchm/common";
import { Button, Card, PageContent, Toolbar } from "@mchm/common";

interface SettingsProps {
 regularVisionOn: boolean;
 regularVisionSwitch: () => void;
}

const Settings = ({ regularVisionSwitch, regularVisionOn }: SettingsProps) => {
 visitorAlert("chefin", "settings");

 return (
  <>
   <Toolbar
    backPath="/"
    backLabel="Chefin"
   />
   <PageContent
    withNav
    className="settings"
   >
    <h1>Settings</h1>
    <Card variant="presentational">
     <span>Low vision mode</span>
     <Button
      variant={`${regularVisionOn ? "positive" : "negative"}`}
      onClick={() => regularVisionSwitch()}
     >
      Turn {regularVisionOn ? "on" : "off"}
     </Button>
    </Card>
    <Card variant="presentational">
     <span>Default servings: 2</span>
    </Card>
   </PageContent>
  </>
 );
};

export default Settings;
