import React from "react";

import { useNavigate } from "react-router-dom";

import { ContextMenu } from "@mchm/common";

import { CoordsInterface } from "@mchm/common";

interface PageMenuProps {
 visible: boolean;
 contextMenuTriggerCoords: CoordsInterface;
 startSearch: () => void;
 closeMenu: () => void;
}

const PageMenu = ({
 visible,
 contextMenuTriggerCoords,
 startSearch,
 closeMenu,
}: PageMenuProps) => {
 const navigateTo = useNavigate();

 const menuItems = [
  {
   name: "Add recipe",
   action: () => {
    navigateTo("/addRecipe");
   },
  },
  {
   name: "Search",
   action: () => {
    startSearch();
    closeMenu();
   },
  },
 ];

 return (
  <ContextMenu
   visible={visible}
   menuItems={menuItems}
   contextMenuTriggerCoords={contextMenuTriggerCoords}
  />
 );
};

export default PageMenu;
