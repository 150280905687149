import React, { useState } from "react";
import Axios from "axios";

import { Fetcher, PageContent, SelectableCard, Toolbar } from "@mchm/common";

const AddScheduleToList = () => {
 const [ingredients, setIngredients] = useState<string[] | null>(null);
 const [productsOnList, setProductsOnList] = useState<string[] | null>(null);

 const selectionHandle = (ingredient: string) => {
  let mutableArray: string[];
  let onList;

  if (productsOnList !== null) {
   mutableArray = [...productsOnList];
  } else {
   mutableArray = [];
  }

  if (mutableArray.includes(ingredient)) {
   mutableArray = mutableArray.filter((product) => product !== ingredient);
   onList = false;
  } else {
   mutableArray.push(ingredient);
   onList = true;
  }

  setProductsOnList(mutableArray);

  Axios.post(
   `${process.env.REACT_APP_BACKEND_URL}/products/updateOne/compose`,
   { name: ingredient, onList: onList }
  );
 };

 return (
  <>
   <Toolbar
    backPath="/schedule"
    backLabel="Schedule"
   />
   <PageContent withNav>
    <Fetcher
     isDataFetchNeeded={ingredients === null}
     url={`${process.env.REACT_APP_BACKEND_URL}/schedule/ingredients`}
     onFetched={(data) => setIngredients(data)}
    />

    <Fetcher
     isDataFetchNeeded={productsOnList === null}
     url={`${process.env.REACT_APP_BACKEND_URL}/products/onList`}
     onFetched={(data) => setProductsOnList(data)}
    />

    {ingredients && productsOnList && (
     <ul className="flex-column">
      {ingredients.map((ingredient) => {
       return (
        <li key={ingredient}>
         <SelectableCard
          selected={productsOnList.includes(ingredient)}
          onClick={() => selectionHandle(ingredient)}
         >
          {ingredient}
         </SelectableCard>
        </li>
       );
      })}
     </ul>
    )}
   </PageContent>
  </>
 );
};

export default AddScheduleToList;
