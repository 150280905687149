import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { visitorAlert } from "@mchm/common";
import { Card, Fetcher, Backdrop, PageContent, Toolbar } from "@mchm/common";

import useDataHandle from "./useDataHandle";
import useOverlayHandle from "./useOverlayHandle";

import PageMenu from "./PageMenu";
import RecalculateModal from "./RecalculateModal";

const Recipe = () => {
 const { name } = useParams();

 if (name === undefined) {
  throw new Error("Invalid URL");
 }

 const { overlayState, openPageMenu, openRecalculateModal, closeOverlay } =
  useOverlayHandle();
 const { recipe, onFetched, recalculateIngredients } =
  useDataHandle(closeOverlay);

 useEffect(() => {
  visitorAlert("chefin", name);
 }, [name]);

 return (
  <>
   <Backdrop
    visible={overlayState.openedOverlay !== null}
    onClick={closeOverlay}
   />
   {recipe !== null && (
    <>
     <PageMenu
      visible={overlayState.openedOverlay === "pageMenu"}
      contextMenuTriggerCoords={overlayState.triggerCoords}
      recipe={recipe}
      openRecalculateModal={openRecalculateModal}
     />
     <RecalculateModal
      visible={overlayState.openedOverlay === "recalculateModal"}
      recipe={recipe}
      onCancel={closeOverlay}
      recalculateIngredients={recalculateIngredients}
     />
    </>
   )}
   <Toolbar
    backLabel="Recipes"
    backPath="/recipes/"
    onEllipsisClick={(event) =>
     openPageMenu({ x: event.clientX, y: event.clientY })
    }
   />
   <PageContent
    className="recipe-page"
    withNav
   >
    <h1>{name}</h1>
    <h2>Ingredients</h2>

    <Fetcher
     isDataFetchNeeded={recipe === null}
     url={`${process.env.REACT_APP_BACKEND_URL}/recipes/${name}`}
     onFetched={onFetched}
     dataName="recipe"
    />

    {recipe !== null && (
     <ul className="flex-column">
      {recipe.ingredients.map((ingredient) => {
       return (
        <li key={ingredient.name}>
         <Card variant="presentational">
          {ingredient.name}
          {Number(ingredient.quantity) ? `: ${ingredient.quantity}` : ""}
         </Card>
        </li>
       );
      })}
     </ul>
    )}
    {recipe?.steps[0] && (
     <>
      <h2>Steps</h2>
      <ul className="flex-column"></ul>
     </>
    )}
    {recipe?.notes && (
     <>
      <h2>Notes</h2>
      <ul className="flex-column">
       {recipe.notes.map((note, index) => {
        if (note !== "") {
         return (
          <li key={index}>
           <Card variant="presentational">{note}</Card>
          </li>
         );
        }
        return null;
       })}
      </ul>
     </>
    )}
   </PageContent>
  </>
 );
};

export default Recipe;
