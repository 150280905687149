import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Fetcher, PageContent, Toolbar } from "@mchm/common";
import RecipeForm from "./RecipeForm";
import NewProductsForm from "./NewProductsForm";

import { ProductInterface, RecipeInterface } from "../../SharedTypes";

const RecipeFormPage = () => {
 const { recipeName } = useParams();

 const [products, setProducts] = useState<string[] | null>(null);
 const [recipe, setRecipe] = useState<RecipeInterface | null>(null);
 const [newProductList, setNewProductList] = useState<string[] | null>(null);

 const onProductsFetched = (data: ProductInterface[]) => {
  const productNames = data.reduce((acc: string[], product) => {
   return [...acc, product.name];
  }, []);

  setProducts(productNames);
 };

 const onRecipeFetched = (data: RecipeInterface) => {
  setRecipe(data);
 };

 const renderForm = () => {
  if (products === null) {
   return null;
  }

  if (recipeName !== undefined) {
   if (recipe === null) {
    return null;
   }
  }

  if (newProductList === null) {
   return (
    <RecipeForm
     products={products}
     recipe={recipe}
     setNewProductList={setNewProductList}
    />
   );
  } else {
   return <NewProductsForm newProductList={newProductList} />;
  }
 };

 return (
  <>
   <Toolbar
    backLabel={recipeName !== undefined ? "Recipe" : "Recipes"}
    backPath={recipeName !== undefined ? `/recipe/${recipeName}` : "/recipes/"}
   />
   <PageContent
    withNav
    className="recipe-form"
   >
    <h1>{`${recipeName !== undefined ? "Edit" : "Add"} recipe`}</h1>
    <Fetcher
     isDataFetchNeeded={products === null}
     url={`${process.env.REACT_APP_BACKEND_URL}/products/eatable`}
     onFetched={onProductsFetched}
     dataName="products"
    />
    <Fetcher
     isDataFetchNeeded={recipeName !== undefined && recipe === null}
     url={`${process.env.REACT_APP_BACKEND_URL}/recipes/${recipeName}`}
     onFetched={onRecipeFetched}
    />
    {renderForm()}
   </PageContent>
  </>
 );
};

export default RecipeFormPage;
