import { useState } from "react";
import Axios from "axios";

import { RecipeInterface } from "../../SharedTypes";

const useDataHandle = (closeOverlay: () => void) => {
 const [recipe, setRecipe] = useState<RecipeInterface | null>(null);

 const onFetched = (data: RecipeInterface) => {
  setRecipe(data);
 };

 const recalculateIngredients = (
  recalculationInput: {
   ingredient: string;
   quantity: number;
  },
  callback: () => void
 ) => {
  Axios.put(`${process.env.REACT_APP_BACKEND_URL}/recipes/recalculate`, {
   recipe: recipe,
   recalculationInput,
  }).then((response) => {
   setRecipe(response.data as RecipeInterface);
   closeOverlay();
   callback();
  });
 };

 return {
  recipe,
  onFetched,
  recalculateIngredients,
 };
};

export default useDataHandle;
