import "./RecipeForm.css";

import {
 useFormData,
 useFormSubmissionState,
 useFormValidationState,
} from "@mchm/common";

import { AddItemButton, Button, ComboBox, Input, Snackbar } from "@mchm/common";

import { FormValidationCriteria } from "@mchm/common";
import { RecipeInterface } from "../../SharedTypes";

// interface RecipeFormDataInterface {
//  name: string;
//  reference: string;
//  ingredients: { name: string; quantity: number }[];
//  steps: string[];
//  notes: string[];
// }

interface RecipeFormProps {
 products: string[];
 recipe: RecipeInterface | null;
 setNewProductList: React.Dispatch<React.SetStateAction<string[] | null>>;
}

//TODO Unique ingredient names - for sure? Maybe its better to allow for duplicatres, for cases like .5 onion for sauce .5 onion for sthg else
const RecipeForm = ({
 products,
 recipe,
 setNewProductList,
}: RecipeFormProps) => {
 const {
  formData,
  formDataChangeHandle,
  addItemToFormDataArray,
  removeItemFromFormDataArray,
 } = useFormData(
  recipe === null
   ? {
      name: "",
      reference: "",
      ingredients: [{ name: "", quantity: "" }],
      steps: [""],
      notes: [""],
     }
   : recipe
 );

 const { formSubmissionState, submissionHandle } = useFormSubmissionState(
  formData,
  `${process.env.REACT_APP_BACKEND_URL}/recipes/${
   recipe === null ? "create" : "edit"
  }`
 );

 const formValidationCriteria: FormValidationCriteria = {
  name: ["required"],
  ingredients: { name: ["required"] },
 };

 const { isEntireFormValid } = useFormValidationState(
  formData,
  formValidationCriteria
 );

 const getNewProductsList = () => {
  return formData.ingredients.reduce(
   (acc: string[], curr: Record<string, any>) => {
    if (products.includes(curr.name) === false) {
     acc.push(curr.name);
    }
    return acc;
   },
   []
  );
 };

 return (
  <>
   <form
    onSubmit={(event) => {
     event.preventDefault();
     const newProductsList = getNewProductsList();
     if (newProductsList.length === 0) {
      submissionHandle(event);
     } else {
      setNewProductList(newProductsList);
     }
    }}
   >
    <fieldset>
     <legend>General</legend>
     <Input
      fieldName="name"
      label="Name"
      formData={formData}
      onValueChange={formDataChangeHandle}
      formValidationCriteria={formValidationCriteria}
     />
     <Input
      fieldName="reference"
      label="Reference recipe"
      formData={formData}
      onValueChange={formDataChangeHandle}
     />
    </fieldset>
    <fieldset>
     <legend>Ingredients</legend>
     <ul className="flex-column">
      {formData.ingredients.map(
       (ingredient: Record<string, any>, index: number) => {
        return (
         <li key={index}>
          <div className="group-wrapper">
           <ComboBox
            fieldName="ingredients"
            propName="name"
            index={index}
            label="Name"
            options={products}
            formData={formData}
            formValidationCriteria={formValidationCriteria}
            onValueChange={formDataChangeHandle}
           />
           <Input
            fieldName="ingredients"
            index={index}
            propName="quantity"
            label="Quantity"
            formData={formData}
            onValueChange={formDataChangeHandle}
           />
           {formData.ingredients.length !== 1 && (
            <Button
             variant="negative"
             onClick={() => {
              removeItemFromFormDataArray("ingredients", index);
             }}
            >
             Remove
            </Button>
           )}
          </div>
         </li>
        );
       }
      )}
     </ul>
     <AddItemButton
      onClick={() =>
       addItemToFormDataArray("ingredients", { name: "", quantity: "" })
      }
     />
    </fieldset>
    <fieldset>
     <legend>Steps</legend>
     <ul className="flex-column">
      {formData.steps.map((step: string, index: number) => {
       return (
        <li key={index}>
         <div className="group-wrapper">
          <Input
           fieldName="steps"
           index={index}
           label="Step"
           formData={formData}
           onValueChange={formDataChangeHandle}
          />
          {formData.steps.length !== 1 && (
           <Button
            variant="negative"
            onClick={() => {
             removeItemFromFormDataArray("steps", index);
            }}
           >
            Remove
           </Button>
          )}
         </div>
        </li>
       );
      })}
     </ul>
     <AddItemButton
      onClick={() => {
       addItemToFormDataArray("steps", "");
      }}
     />
    </fieldset>
    <fieldset>
     <legend>Notes</legend>
     <ul className="flex-column">
      {formData.notes.map((note: string, index: number) => {
       return (
        <li key={index}>
         <div className="group-wrapper">
          <Input
           fieldName="notes"
           index={index}
           label="Note"
           formData={formData}
           onValueChange={formDataChangeHandle}
          />
          {formData.notes.length !== 1 && (
           <Button
            variant="negative"
            onClick={() => {
             removeItemFromFormDataArray("notes", index);
            }}
           >
            Remove
           </Button>
          )}
         </div>
        </li>
       );
      })}
     </ul>
     <AddItemButton
      onClick={() => {
       addItemToFormDataArray("notes", "");
      }}
     />
    </fieldset>
    <Button
     type="submit"
     disabled={
      formSubmissionState.submitting === true || isEntireFormValid === false
     }
    >
     Submit
    </Button>
   </form>

   <Snackbar formSubmissionState={formSubmissionState} />
  </>
 );
};

export default RecipeForm;
