import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";

import {
 Fetcher,
 PageContent,
 SearchBox,
 SelectableCard,
 Toolbar,
} from "@mchm/common";

import { Weekday } from "../SharedTypes";

const DishPicker = () => {
 const { targetDay } = useParams<{ targetDay: Weekday }>();

 if (targetDay === undefined) {
  throw new Error("Invalid URL");
 }

 const [dishes, setDishes] = useState<string[] | null>(null);
 const [schedule, setSchedule] = useState<Record<Weekday, string[]> | null>(
  null
 );

 const dishSelectionHandle = (dish: string) => {
  if (schedule !== null) {
   let action;
   if (schedule[targetDay].includes(dish)) {
    action = "remove";
   } else {
    action = "add";
   }

   let mutableArray = [...schedule[targetDay]];

   if (action === "remove") {
    mutableArray = mutableArray.filter((dishName) => dishName !== dish);
   } else {
    mutableArray.push(dish);
   }

   const updatedSchedule = {
    ...schedule,
    [targetDay]: mutableArray,
   };

   setSchedule(updatedSchedule);

   Axios.post(`${process.env.REACT_APP_BACKEND_URL}/schedule`, updatedSchedule);
  }
 };

 return (
  <>
   <Toolbar
    backPath="/schedule"
    backLabel="Schedule"
   />
   <PageContent withNav>
    <SearchBox
     onChange={() => {}}
     placeholder="Search"
    />

    <Fetcher
     isDataFetchNeeded={dishes === null}
     url={`${process.env.REACT_APP_BACKEND_URL}/schedule/allDishes`}
     onFetched={(data) => setDishes(data)}
    />

    <Fetcher
     isDataFetchNeeded={schedule === null}
     url={`${process.env.REACT_APP_BACKEND_URL}/schedule/`}
     onFetched={(data) => setSchedule(data)}
    />

    {dishes && schedule && (
     <ul className="flex-column">
      {dishes.map((dish) => {
       return (
        <li key={dish}>
         <SelectableCard
          selected={schedule[targetDay].includes(dish)}
          onClick={() => dishSelectionHandle(dish)}
         >
          {dish}
         </SelectableCard>
        </li>
       );
      })}
     </ul>
    )}
   </PageContent>
  </>
 );
};

export default DishPicker;
