import React from "react";
import { useNavigate } from "react-router-dom";

import { ContextMenu } from "@mchm/common";

import { CoordsInterface } from "@mchm/common";
import { RecipeInterface } from "../../SharedTypes";

interface PageMenuProps {
 visible: boolean;
 contextMenuTriggerCoords: CoordsInterface;
 recipe: RecipeInterface;
 openRecalculateModal: () => void;
}

const PageMenu = ({
 visible,
 contextMenuTriggerCoords,
 recipe,
 openRecalculateModal,
}: PageMenuProps) => {
 const navigateTo = useNavigate();

 const menuItems = [
  {
   name: "Recalculate",
   action: () => openRecalculateModal(),
  },
  {
   name: "Edit recipe",
   action: () => {
    navigateTo(`/recipe/edit/${recipe.name}`);
   },
  },
 ];

 if (recipe.reference !== "") {
  menuItems.push({
   name: "View reference",
   action: () => window.open(recipe.reference),
  });
 }

 return (
  <ContextMenu
   visible={visible}
   contextMenuTriggerCoords={contextMenuTriggerCoords}
   menuItems={menuItems}
  />
 );
};

export default PageMenu;
